export const TABLE_UPDATE = "DASHBOARD/TABLE_UPDATE";

const types = {
    DASHBOARD_FAILED: "DASHBOARD_FAILED",
    DASHBOARD_REQUEST: "DASHBOARD_REQUEST",
    DASHBOARD_SUCCESS: "DASHBOARD_SUCCESS",

    TABLE_UPDATE,
};

export default types;
