export const FETCH_SUCCESS = "EVENTS/FETCH_SUCCESS";
export const FETCH_TICKETS_SUCCESS = "EVENTS_TICKETS/FETCH_SUCCESS";
export const TABLE_UPDATE = "EVENTS/TABLE_UPDATE";
export const TABLE_TICKETS_UPDATE = "EVENTS_TICKETS/TABLE_UPDATE";

const types = {
    DESTROY_FAILED: "EVENTS/DESTROY_FAILED",
    DESTROY_REQUEST: "EVENTS/DESTROY_REQUEST",
    DESTROY_SUCCESS: "EVENTS/DESTROY_SUCCESS",

    DUPLICATE_REQUEST: "EVENTS/DUPLICATE_REQUEST",
    DUPLICATE_FAILED: "EVENTS/DUPLICATE_FAILED",
    DUPLICATE_SUCCESS: "EVENTS/DUPLICATE_SUCCESS",

    FETCH_FAILED: "EVENTS/FETCH_FAILED",
    FETCH_REQUEST: "EVENTS/FETCH_REQUEST",
    FETCH_SUCCESS,

    FETCH_ALL_FAILED: "EVENTS/FETCH_ALL_FAILED",
    FETCH_ALL_REQUEST: "EVENTS/FETCH_ALL_REQUEST",
    FETCH_ALL_SUCCESS: "EVENTS/FETCH_ALL_SUCCESS",

    FETCH_TICKETS_FAILED: "EVENTS_TICKETS/FETCH_FAILED",
    FETCH_TICKETS_REQUEST: "EVENTS_TICKETS/FETCH_REQUEST",
    FETCH_TICKETS_SUCCESS,

    EXPORT_TICKETS_FAILED: "EVENTS_TICKETS/EXPORT_FAILED",
    EXPORT_TICKETS_REQUEST: "EVENTS_TICKETS/EXPORT_REQUEST",
    EXPORT_TICKETS_SUCCESS: "EVENTS_TICKETS/EXPORT_SUCCESS",
    
    GET_FAILED: "EVENTS/GET_FAILED",
    GET_REQUEST: "EVENTS/GET_REQUEST",
    GET_SUCCESS: "EVENTS/GET_SUCCESS",

    STORE_FAILED: "EVENTS/STORE_FAILED",
    STORE_REQUEST: "EVENTS/STORE_REQUEST",
    STORE_SUCCESS: "EVENTS/STORE_SUCCESS",

    UPDATE_FAILED: "EVENTS/UPDATE_FAILED",
    UPDATE_REQUEST: "EVENTS/UPDATE_REQUEST",
    UPDATE_SUCCESS: "EVENTS/UPDATE_SUCCESS",
    

    TABLE_UPDATE,
    TABLE_TICKETS_UPDATE,
};

export default types;
