import DayPicker from "./DayPicker";

import { Box, Button, Grid, Typography } from "@mui/material";
import trans from "helpers/trans";
import moment from "moment";
import { FC, useEffect, useState } from "react";

interface IDayPickerRange {
    onChange: Function;
    after: any;
    before: any;
}
const DayPickerRange: FC<IDayPickerRange> = ({ onChange, after, before }) => {
    const getInitState = (data: any) => {
        const before = data?.before !== undefined ? data?.before : null;

        return {
            after: data?.after !== undefined ? data?.after : null,
            mouseOver: before,
            before,
        };
    };

    const [state, setState] = useState(getInitState({ before, after }));

    useEffect(() => {
        setState(getInitState({ before, after }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [after, before]);

    const isSelectingFirstDay = (after: any, before: any, day: any) => {
        const isBeforeFirstDay =
            after && moment(day).isBefore(moment(after), "day");
        const isRangeSelected = after && before;
        return !after || isBeforeFirstDay || isRangeSelected;
    };

    const onClickDay = (day: any) => {
        const { after, before } = state;
        const selectedDay = day?.from;

        if (after && before && selectedDay >= after && selectedDay <= before) {
            onClickReset();
            return;
        }

        if (isSelectingFirstDay(after, before, selectedDay)) {
            setState({
                after: selectedDay,
                before: null,
                mouseOver: null,
            });
        } else {
            setState({
                ...state,
                before: selectedDay,
                mouseOver: selectedDay,
            });
        }
    };

    const onClickReset = () => {
        setState(getInitState(null));
    };

    const onClickUse = () => {
        onChange(moment(state.after), moment(state.before));
    };

    const onMouseOver = (day: any) => {
        const { after, before } = state;
        if (!isSelectingFirstDay(after, before, day)) {
            setState({
                ...state,
                mouseOver: day,
            });
        }
    };

    const renderMessage = () => {
        if (!state.after && !state.before) {
            return trans("ui.dayPicker.message.first");
        } else if (state.after && !state.before) {
            return trans("ui.dayPicker.message.last");
        } else {
            return trans("ui.dayPicker.message.selected", {
                after: moment(state.after).format("DD/MM/YYYY"),
                before: moment(state.before).format("DD/MM/YYYY"),
            });
        }
    };

    return (
        <Box sx={{ my: 2 }}>
            <DayPicker
                onSelect={onClickDay}
                mode="range"
                onDayMouseEnter={onMouseOver}
                defaultMonth={
                    state.after ? moment(state.after).toDate() : new Date()
                }
                selected={[
                    state.after,
                    {
                        from: state.after,
                        to: state.mouseOver,
                    },
                ]}
            />

            <Typography align="center">{renderMessage()}</Typography>

            <Grid container direction="row" spacing={1}>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        onClick={onClickReset}
                        color="gray2"
                        variant="contained"
                    >
                        {trans("ui.dayPicker.button.reset")}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        color="secondary"
                        disabled={state.after === null || state.before === null}
                        fullWidth
                        onClick={onClickUse}
                        variant="contained"
                    >
                        {trans("ui.dayPicker.button.use")}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DayPickerRange;
