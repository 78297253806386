import { Box, Icon, Typography } from "@mui/material";
import { enGB, nb, sv, nn, Locale } from "date-fns/locale";
import React from "react";
import {
    DateRange,
    DayPicker,
    DayPickerSingleProps,
    useNavigation,
} from "react-day-picker";
import "react-day-picker/src/style.css";
import moment from "moment";

interface IGPDayPicker extends Omit<DayPickerSingleProps, "mode" | "selected"> {
    numberOfMonths?: number;
    mode?: any;
    selected?: Date | Date[] | DateRange | undefined;
    onSelect?: any;
}

const localeMap: { [key: string]: Locale } = {
    en: enGB,
    nb: nb,
    sv: sv,
    nn: nn,
};

function NavbarElement(lang: string) {
    const { currentMonth, previousMonth, goToMonth, nextMonth } =
        useNavigation();
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <Icon
                onClick={() => previousMonth && goToMonth(previousMonth)}
                sx={{
                    color: "grey.600",
                    cursor: "pointer",
                    fontSize: (theme) => theme.spacing(4),
                    "&:hover": {
                        color: "text.primary",
                    },
                }}
            >
                navigate_before
            </Icon>
            <Typography
                sx={{
                    fontSize: 18,
                    fontWeight: 500,
                }}
            >
                {moment(currentMonth)
                    .locale(lang === "en" ? "en-gb" : lang)
                    .format("MMMM")}
            </Typography>
            <Icon
                onClick={() => nextMonth && goToMonth(nextMonth)}
                sx={{
                    color: "grey.600",
                    cursor: "pointer",
                    fontSize: (theme) => theme.spacing(4),
                    "&:hover": {
                        color: "text.primary",
                    },
                }}
            >
                navigate_next
            </Icon>
        </Box>
    );
}

const GPDayPicker = (props: IGPDayPicker) => {
    const { numberOfMonths = 1, ...restProps } = props;
    const lang = document.documentElement.lang;

    return (
        <React.Fragment>
            <style>
                {`
                    .rdp {
                        display: flex;
                        justify-content: center;
                    }
                    .DayPicker-wrapper {
                        outline: none;
                    }
                    .rdp-months {
                        display: flex;
                        justify-content: center; 
                        width: 100%;
                    }
                    .rdp-day {
                        border-radius: 0;
                        outline: none;
                    }
                    .rdp-week {
                        margin: 0 6px;
                        width: calc(${100 / numberOfMonths}% - 12px);
                    }
                    .rdp-month .rdp-caption {
                        text-align: center;
                        width: 100%;
                    }
                    .rdp-table {
                        width: 100%;
                        max-width: 100%;    
                    }
                    .rdp-month {
                        width: 100%;
                    }
                    .rdp-cell button {
                        max-width: 100%;
                        width: 100%;
                        border-radius: 0 !important;
                    }
                    .rdp-cell button:hover {
                        background-color: #51a0fa;
                    }
                `}
            </style>
            <DayPicker
                locale={localeMap[lang] || enGB}
                weekStartsOn={1}
                fixedWeeks={true}
                numberOfMonths={numberOfMonths}
                components={{
                    Caption: () => NavbarElement(lang),
                }}
                {...restProps}
            />
        </React.Fragment>
    );
};

export default GPDayPicker;
