import useRequest from "api/useRequest";
import { bookingsSendConfirmationEmail } from "../actions";
import { Box } from "@mui/material";
import { modalHide } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { FC, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Form,
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
    Settings,
    SettingsItem,
    TextField,
} from "ui";

interface IBookingsConfirmationEmailModal {
    customerEmail: string;
    id: string;
    reload: Function;
}

const BookingsConfirmationEmailModal: FC<IBookingsConfirmationEmailModal> = ({
    customerEmail,
    id,
    reload,
}) => {
    const dispatch = useDispatch();
    const formRef = useRef<any>(null);

    const { request, errors, isLoading, message, status } = useRequest();

    const handleCloseModal = () => {
        dispatch(modalHide("BookingsConfirmationEmailModal"));
    };

    const handleSave = () => {
        if (formRef.current) {
            request(
                bookingsSendConfirmationEmail(
                    id,
                    formRef.current.getData(),
                    (response: AxiosResponse) => {
                        if (response.status === 204) {
                            dispatch(
                                modalHide("BookingsConfirmationEmailModal")
                            );
                            reload();
                        }
                    }
                )
            );
        }
    };

    return (
        <Modal open fullWidth maxWidth="xs">
            <ModalTitle onClose={handleCloseModal}>
                {trans("event.bookings.confirmationEmail")}
            </ModalTitle>
            <ModalContent>
                <RequestMessage
                    message={message}
                    status={status}
                    sx={{
                        mb: 1,
                    }}
                />
                <Form
                    ref={formRef}
                    data={{}}
                    fields={{
                        customer_email: { default: customerEmail },
                    }}
                    errors={errors}
                    onSubmit={() => {}}
                    unsaved={false}
                >
                    <Settings>
                        <SettingsItem
                            label={trans(
                                "event.bookings.modal.bookingsConfirmationEmailModal.label.customerEmail"
                            )}
                        >
                            <TextField
                                id="customer_email"
                                fullWidth
                                autoFocus
                            />
                        </SettingsItem>
                    </Settings>
                </Form>
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={handleCloseModal}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <LoadingButton
                        sx={{ flex: 1 }}
                        onClick={handleSave}
                        loading={isLoading}
                    >
                        {trans("global.button.send")}
                    </LoadingButton>
                </Box>
            </ModalActions>
        </Modal>
    );
};
export default BookingsConfirmationEmailModal;
