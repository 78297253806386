import TicketsSelectModal from "./modals/TicketsSelectModal";
import EventsTable from "./reducers/EventsTableReducer";
import EventsTicketsTable from "./reducers/EventsTicketsTableReducer";
import EventsSaga from "./saga";

import { registerModals } from "modals";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const permissionKey = "event";
export const url = `/events`;

export interface IConfig {
    permissions: {
        create: boolean;
        delete: boolean;
        read: boolean;
        update: boolean;
        ticketRead: boolean;
    };
    routing: string[];
    url: string;
}

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
            ticketRead: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];


    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;
        registerModals({ TicketsSelectModal });
        store.reducerManager?.add("EventsTable", EventsTable);
        store.injectSaga?.("EventsSaga", EventsSaga);
        routing.push({
            path: url,
            element: <AsyncComponent path="Events/pages/EventsIndexPage" />,
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;

        routing.push({
            path: `${url}/create`,
            element: <AsyncComponent path="Events/pages/EventsCreatePage" />,
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;

        routing.push({
            path: `${url}/:id/edit`,
            element: <AsyncComponent path="Events/pages/EventsEditPage" />,
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".ticket.read") !== -1) {
        config.permissions.ticketRead = true;
        store.reducerManager?.add("EventTicketsTable", EventsTicketsTable);

        routing.push({
            path: `${url}/:id/tickets`,
            element: <AsyncComponent path="Events/pages/EventsTicketsPage" />,
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
