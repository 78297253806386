import { merge } from "lodash";
import { useSelector } from "react-redux";

import ChooseLocationModal from "app/Me/modals/ChooseLocationModal";
import ConfirmationModal from "app/App/modals/ConfirmationModal";
import CookieModal from "app/App/modals/CookieModal";
import CountrySelectModal from "ui/CountrySelect/CountrySelectModal";
import PerdiodFilterModal from "ui/Filters/PerdiodFilterModal";
import AvaliableApplicationsModal from "app/Me/modals/AvaliableApplicationsModal"
import ReleaseModal from "app/App/modals/ReleaseModal";

interface IModal {
    [key: string]: any;
}

let data: IModal = {
    AvaliableApplicationsModal,
    ConfirmationModal,
    CookieModal,
    ChooseLocationModal,
    ChooseCountryModal: CountrySelectModal,
    ReleaseModal,
    PerdiodFilterModal,
};

export function registerModals(modals: any) {
    data = merge(data, modals);
}

function Modals() {
    const modals = useSelector((state: any) => state.app.modals);

    const modalsToShow = [];

    for (let idx in modals) {
        const modal: IModal = modals[idx];

        if (data[modal.id] !== undefined) {
            let View = data[modal.id];

            modalsToShow.push(
                <View key={modal.id} id={modal.id} idx={idx} {...modal.props} />
            );
        }
    }

    return <>{modalsToShow}</>;
}

export default Modals;
