import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    destroy: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/events/${url.id}`, config),
    duplicate: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.post(`/events/${url.id}/duplicate`, data, config),
    fetch: (config: AxiosRequestConfig) => api.get(`/events`, config),
    fetchAll: (config: AxiosRequestConfig) => api.get(`/events/all`, config),
    exportTickets: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/events/${url.id}/tickets/export`, {
            ...config,
            responseType: "blob",
        }),
    fetchTickets: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/events/${url.id}/tickets`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/events/${url.id}`, config),
    store: (data: object, config: AxiosRequestConfig) =>
        api.post(`/events`, data, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/events/${url.id}`, data, config),
};

export default requests;
