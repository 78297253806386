import api from "./api";
import types from "./types";
import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import { downloadFromRequest } from "helpers/download";
import trans from "helpers/trans";
import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

function* destroy(action: AnyAction): any {
    const response = yield call(request, api.destroy, action, "delete");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("event.bookings.saga.content.destroy"),
            })
        );
    } else {
        yield put(
            snackbarShow({
                content: response.data.message,
                severity: "error",
            })
        );
    }
}

function* exportBooking(action: AnyAction): any {
    const response = yield call(request, api.export, action);
    if (response.status === 200) {
        downloadFromRequest(response)
        yield put(
            snackbarShow({
                content: trans("event.bookings.saga.content.export"),
            })
        );
    }
}

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

function* fetchAll(action: AnyAction) {
    yield call(request, api.fetchAll, action);
}

function* get(action: AnyAction) {
    yield call(request, api.get, action);
}

function* store(action: AnyAction): any {
    const response = yield call(request, api.store, action, "post");

    if (response.status === 201) {
        yield put(
            snackbarShow({
                content: trans("event.bookings.saga.content.store"),
            })
        );
    }
}

function* update(action: AnyAction): any {
    const response = yield call(request, api.update, action, "put");

    if (response.status === 200) {
        yield put(
            snackbarShow({
                content: trans("event.bookings.saga.content.update"),
            })
        );
    }
}

function* refund(action: AnyAction): any {
    const response = yield call(request, api.refund, action, "put");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("event.bookings.saga.content.refund"),
            })
        );
    }
}

function* refundAll(action: AnyAction): any {
    const response = yield call(request, api.refundAll, action, "put");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("event.bookings.saga.content.refundAll"),
            })
        );
    }
}


function* sendEmail(action: AnyAction): any {
    const response = yield call(request, api.sendEmail, action, "post");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("event.bookings.saga.content.sendEmail"),
            })
        );
    }
}

function* sendConfirmationEmail(action: AnyAction): any {
    const response = yield call(request, api.sendConfirmationEmail, action, "post");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("event.bookings.saga.content.sendConfirmationEmail"),
            })
        );
    }
}

export default function* saga() {
    yield takeLatest(types.SEND_EMAIL_REQUEST, sendEmail);
    yield takeLatest(types.SEND_CONFIRMATION_EMAIL_REQUEST, sendConfirmationEmail);
    yield takeLatest(types.DESTROY_REQUEST, destroy);
    yield takeLatest(types.EXPORT_REQUEST, exportBooking);
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.FETCH_ALL_REQUEST, fetchAll);
    yield takeLatest(types.GET_REQUEST, get);
    yield takeLatest(types.REFUND_REQUEST, refund);
    yield takeLatest(types.REFUND_ALL_REQUEST, refundAll);
    yield takeLatest(types.STORE_REQUEST, store);
    yield takeLatest(types.UPDATE_REQUEST, update);
}
