import BookingsRefundModal from "./modals/BookingsRefundModal";
import BookingsSendEmailModal from "./modals/BookingsSendEmailModal";
import BookingsConfirmationEmailModal from "./modals/BookingsConfirmationEmailModal";
import BookingsUpsalesModal from "./modals/BookingsUpsalesModal";
import BookingsTable from "./reducers/BookingsTableReducer";
import BookingsSaga from "./saga";

import { registerModals } from "modals";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { permissionsType, configType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const permissionKey = "booking";
export const url = `/bookings`;

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: configType = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;
        registerModals({
            BookingsRefundModal,
        });
        store.reducerManager?.add("BookingsTable", BookingsTable);
        store.injectSaga?.("BookingsSaga", BookingsSaga);
        routing.push({
            path: url,
            element: <AsyncComponent path="Bookings/pages/BookingsIndexPage" />,
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;
        registerModals({
            BookingsUpsalesModal,
        });
        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="Bookings/pages/BookingsCreatePage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;
        registerModals({
            BookingsSendEmailModal,
            BookingsConfirmationEmailModal,
            BookingsUpsalesModal,
        });
        routing.push({
            path: `${url}/:id/edit`,
            element: <AsyncComponent path="Bookings/pages/BookingsEditPage" />,
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
