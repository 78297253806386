export const FETCH_SUCCESS = "BOOKINGS/FETCH_SUCCESS";
export const TABLE_UPDATE = "BOOKINGS/TABLE_UPDATE";

const types = {
    EXPORT_FAILED: "BOOKINGS/EXPORT_FAILED",
    EXPORT_REQUEST: "BOOKINGS/EXPORT_REQUEST",
    EXPORT_SUCCESS: "BOOKINGS/EXPORT_SUCCESS",

    SEND_EMAIL_FAILED: "BOOKINGS/SEND_EMAIL_FAILED",
    SEND_EMAIL_REQUEST: "BOOKINGS/SEND_EMAIL_REQUEST",
    SEND_EMAIL_SUCCESS: "BOOKINGS/SEND_EMAIL_SUCCESS",

    SEND_CONFIRMATION_EMAIL_FAILED: "BOOKINGS/SEND_CONFIRMATION_EMAIL_FAILED",
    SEND_CONFIRMATION_EMAIL_REQUEST: "BOOKINGS/SEND_CONFIRMATION_EMAIL_REQUEST",
    SEND_CONFIRMATION_EMAIL_SUCCESS: "BOOKINGS/SEND_CONFIRMATION_EMAIL_SUCCESS",

    DESTROY_FAILED: "BOOKINGS/DESTROY_FAILED",
    DESTROY_REQUEST: "BOOKINGS/DESTROY_REQUEST",
    DESTROY_SUCCESS: "BOOKINGS/DESTROY_SUCCESS",

    FETCH_FAILED: "BOOKINGS/FETCH_FAILED",
    FETCH_REQUEST: "BOOKINGS/FETCH_REQUEST",
    FETCH_SUCCESS,

    FETCH_ALL_FAILED: "BOOKINGS/FETCH_ALL_FAILED",
    FETCH_ALL_REQUEST: "BOOKINGS/FETCH_ALL_REQUEST",
    FETCH_ALL_SUCCESS: "BOOKINGS/FETCH_ALL_SUCCESS",

    GET_FAILED: "BOOKINGS/GET_FAILED",
    GET_REQUEST: "BOOKINGS/GET_REQUEST",
    GET_SUCCESS: "BOOKINGS/GET_SUCCESS",

    REFUND_FAILED: "BOOKINGS/REFUND_FAILED",
    REFUND_REQUEST: "BOOKINGS/REFUND_REQUEST",
    REFUND_SUCCESS: "BOOKINGS/REFUND_SUCCESS",

    REFUND_ALL_FAILED: "BOOKINGS/REFUND_ALL_FAILED",
    REFUND_ALL_REQUEST: "BOOKINGS/REFUND_ALL_REQUEST",
    REFUND_ALL_SUCCESS: "BOOKINGS/REFUND_ALL_SUCCESS",

    STORE_FAILED: "BOOKINGS/STORE_FAILED",
    STORE_REQUEST: "BOOKINGS/STORE_REQUEST",
    STORE_SUCCESS: "BOOKINGS/STORE_SUCCESS",

    UPDATE_FAILED: "BOOKINGS/UPDATE_FAILED",
    UPDATE_REQUEST: "BOOKINGS/UPDATE_REQUEST",
    UPDATE_SUCCESS: "BOOKINGS/UPDATE_SUCCESS",

    TABLE_UPDATE,
};

export default types;
