import Dashboard from "./reducer"
import DashboardSaga from "./saga";

import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { permissionsType, configType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const permissionKey = "dashboard";
export const url = `/dashboard`;

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: configType = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;
        store.reducerManager?.add("Dashboard", Dashboard);
        store.injectSaga?.("DashboardSaga", DashboardSaga);
        routing.push({
            path: url,
            element: <AsyncComponent path="Dashboard/pages/DashboardIndexPage" />,
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
