import {
    Grid,
    List,
    ListItemButton,
    ListItemText,
    Tab,
    Tabs,
} from "@mui/material";
import { modalHide } from "app/App/actions";
import trans from "helpers/trans";
import moment from "moment";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalContent, ModalTitle } from "ui";
import DayPickerRange from "ui/DayPicker/DayPickerRange";
interface PeriodFilterModal {
    afterDate: string;
    beforeDate: string;
    periodType: string;
    onChange: Function;
}
const PerdiodFilterModal: FC<PeriodFilterModal> = ({
    afterDate,
    beforeDate,
    periodType,
    onChange,
}) => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState<string>(
        periodType !== "individual" ? "predefined" : "individual"
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [individual] = useState<{
        after: any;
        before: any;
    }>({
        after:
            activeTab === "individual"
                ? afterDate !== null
                    ? afterDate
                    : null
                : null,
        before:
            activeTab === "individual"
                ? beforeDate !== null
                    ? beforeDate
                    : null
                : null,
    });

    const handleClose = () => {
        dispatch(modalHide("PerdiodFilterModal"));
    };
    const handleChangeTab = (event: React.SyntheticEvent, value: string) => {
        setActiveTab(value);
    };

    const handleClickPeriod = (periodType: string) => {
        switch (periodType) {
            case "last_month":
            case "last_week":
            case "last_year":
                const lastPeriodType = periodType.replace("last_", "") as
                    | "month"
                    | "week"
                    | "year";
                const lastPeriodStart = moment()
                    .subtract(1, lastPeriodType)
                    .startOf(lastPeriodType);
                const lastPeriodEnd = lastPeriodStart
                    .clone()
                    .endOf(lastPeriodType);
                onChange(
                    lastPeriodStart.format("YYYY-MM-DD"),
                    lastPeriodEnd.format("YYYY-MM-DD"),
                    periodType
                );
                break;

            case "month":
            case "week":
            case "year":
                const periodStart = moment().startOf(periodType);
                const periodEnd = periodStart.clone().endOf(periodType);
                onChange(
                    periodStart.format("YYYY-MM-DD"),
                    periodEnd.format("YYYY-MM-DD"),
                    periodType
                );
                break;

            case "today":
                const today = moment().format("YYYY-MM-DD");
                onChange(today, today, "today");
                break;

            case "yesterday":
                const yesterday = moment()
                    .subtract(1, "day")
                    .format("YYYY-MM-DD");
                onChange(yesterday, yesterday, "yesterday");
                break;

            //no default
        }
        handleClose();
    };

    const handleChangeIndividual = (after: any, before: any) => {
        onChange(
            moment(after).format("YYYY-MM-DD"),
            moment(before).format("YYYY-MM-DD"),
            "individual"
        );
    };

    const renderContent = () => {
        if (activeTab === "predefined") {
            return (
                <Grid container direction="row">
                    <Grid item xs={6}>
                        <List>
                            <ListItemButton
                                sx={{
                                    border: (theme: any) =>
                                        "1px solid " + theme.palette.grey[300],
                                }}
                                selected={periodType === "today"}
                                onClick={(event: React.SyntheticEvent) =>
                                    handleClickPeriod("today")
                                }
                            >
                                <ListItemText
                                    primary={trans(
                                        "ui.filters.periodFilterModal.today"
                                    )}
                                    sx={{ textAlign: "center" }}
                                />
                            </ListItemButton>

                            <ListItemButton
                                sx={{
                                    border: (theme: any) =>
                                        "1px solid " + theme.palette.grey[300],
                                }}
                                selected={periodType === "week"}
                                onClick={(event: React.SyntheticEvent) =>
                                    handleClickPeriod("week")
                                }
                            >
                                <ListItemText
                                    primary={trans(
                                        "ui.filters.periodFilterModal.week"
                                    )}
                                    sx={{ textAlign: "center" }}
                                />
                            </ListItemButton>

                            <ListItemButton
                                sx={{
                                    border: (theme: any) =>
                                        "1px solid " + theme.palette.grey[300],
                                }}
                                selected={periodType === "month"}
                                onClick={(event: React.SyntheticEvent) =>
                                    handleClickPeriod("month")
                                }
                            >
                                <ListItemText
                                    primary={trans(
                                        "ui.filters.periodFilterModal.month"
                                    )}
                                    sx={{ textAlign: "center" }}
                                />
                            </ListItemButton>

                            <ListItemButton
                                sx={{
                                    border: (theme: any) =>
                                        "1px solid " + theme.palette.grey[300],
                                }}
                                selected={periodType === "year"}
                                onClick={(event: React.SyntheticEvent) =>
                                    handleClickPeriod("year")
                                }
                            >
                                <ListItemText
                                    primary={trans(
                                        "ui.filters.periodFilterModal.year"
                                    )}
                                    sx={{ textAlign: "center" }}
                                />
                            </ListItemButton>
                        </List>
                    </Grid>
                    <Grid item xs={6}>
                        <List>
                            <ListItemButton
                                sx={{
                                    border: (theme: any) =>
                                        "1px solid " + theme.palette.grey[300],
                                }}
                                selected={periodType === "yesterday"}
                                onClick={(event: React.SyntheticEvent) =>
                                    handleClickPeriod("yesterday")
                                }
                            >
                                <ListItemText
                                    primary={trans(
                                        "ui.filters.periodFilterModal.yesterday"
                                    )}
                                    sx={{ textAlign: "center" }}
                                />
                            </ListItemButton>

                            <ListItemButton
                                sx={{
                                    border: (theme: any) =>
                                        "1px solid " + theme.palette.grey[300],
                                }}
                                selected={periodType === "last_week"}
                                onClick={(event: React.SyntheticEvent) =>
                                    handleClickPeriod("last_week")
                                }
                            >
                                <ListItemText
                                    primary={trans(
                                        "ui.filters.periodFilterModal.last_week"
                                    )}
                                    sx={{ textAlign: "center" }}
                                />
                            </ListItemButton>

                            <ListItemButton
                                sx={{
                                    border: (theme: any) =>
                                        "1px solid " + theme.palette.grey[300],
                                }}
                                selected={periodType === "last_month"}
                                onClick={(event: React.SyntheticEvent) =>
                                    handleClickPeriod("last_month")
                                }
                            >
                                <ListItemText
                                    primary={trans(
                                        "ui.filters.periodFilterModal.last_month"
                                    )}
                                    sx={{ textAlign: "center" }}
                                />
                            </ListItemButton>

                            <ListItemButton
                                sx={{
                                    border: (theme: any) =>
                                        "1px solid " + theme.palette.grey[300],
                                }}
                                selected={periodType === "last_year"}
                                onClick={(event: React.SyntheticEvent) =>
                                    handleClickPeriod("last_year")
                                }
                            >
                                <ListItemText
                                    primary={trans(
                                        "ui.filters.periodFilterModal.last_year"
                                    )}
                                    sx={{ textAlign: "center" }}
                                />
                            </ListItemButton>
                        </List>
                    </Grid>
                </Grid>
            );
        } else if (activeTab === "individual") {
            return (
                <DayPickerRange
                    onChange={handleChangeIndividual}
                    after={individual.after}
                    before={individual.before}
                />
            );
        }
    };

    return (
        <Modal onClose={handleClose} open maxWidth="xs" fullWidth>
            <ModalTitle style={{ paddingBottom: 0 }} onClose={handleClose}>
                {trans("ui.filters.periodFilterModal.title")}
            </ModalTitle>
            <ModalContent>
                <Tabs
                    value={activeTab}
                    onChange={handleChangeTab}
                    variant="fullWidth"
                >
                    {["predefined", "individual"].map((item: any) => (
                        <Tab
                            key={`type-${item}`}
                            label={trans(
                                `ui.filters.periodFilterModal.${item}`
                            )}
                            value={item}
                            sx={{ textTransform: "lowercase" }}
                        />
                    ))}
                </Tabs>

                {renderContent()}
            </ModalContent>
        </Modal>
    );
};

export default PerdiodFilterModal;
