import { FETCH_SUCCESS, TABLE_UPDATE } from "../types";

import { MANAGE_TABLE } from "app/ManageTables/types";
import { tableResponse, tableUpdate } from "helpers/reducers";
import { omit, pick } from "lodash";
import { AnyAction } from "redux";
import { IGPTable } from "ui/Table/Table";

interface IBookingsTable extends IGPTable {
    periodType: string;
}
function BookingsTableReducer(
    state: IBookingsTable = {
        id: "BookingsTable",
        columns: [],
        availableColumns: [],
        filters: {
            booking_status_filter: "CONFIRMED",
        },
        filterSettings: {
            open: false,
            total: 0,
        },
        pagination: {
            page: 1,
            per_page: 20,
            isLastPage: true,
            total: 0,
        },
        search: "",
        selection: {
            id: "id",
            selected: [],
        },
        selectionActions: null,
        sort: { created_at: "desc" },
        periodType: "predefined",
    },
    action: AnyAction
) {
    switch (action.type) {
        case FETCH_SUCCESS:
            if (action.response.config.params._table_id === state.id) {
                return tableResponse(state, action);
            }
            return state;

        case MANAGE_TABLE:
            if (action.data.id === state.id) {
                return {
                    ...state,
                    ...action.data,
                };
            }
            return state;

        case TABLE_UPDATE:
            const newAction = {
                ...action,
                data:
                    typeof action.data === "object" && action.data !== null
                        ? omit(action.data, "periodType")
                        : action.data,
            };
            return action.action === "clearFilters"
                ? {
                      ...tableUpdate(state, newAction),
                      filters: pick(state.filters, "booking_status_filter"),
                      periodType: "predefined",
                  }
                : {
                      ...tableUpdate(state, newAction),
                      periodType: action.data?.periodType || state.periodType,
                  };

        default:
            return state;
    }
}

export default BookingsTableReducer;
