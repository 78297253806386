import { FETCH_TICKETS_SUCCESS, TABLE_TICKETS_UPDATE } from "../types";
import { tableResponse, tableUpdate } from "helpers/reducers";
import { IGPTable } from "ui/Table/Table";
import { AnyAction } from "redux";
import { MANAGE_TABLE } from "app/ManageTables/types";
import { omit } from "lodash";

interface IEventsTicketsTable extends IGPTable {
    periodType: string;
}

function EventsTicketsReducer(
    state: IEventsTicketsTable = {
        id: "EventTicketsTable",
        columns: [],
        availableColumns: [],
        filters: {},
        filterSettings: {
            open: false,
            total: 0,
        },
        pagination: {
            page: 1,
            per_page: 20,
            isLastPage: true,
            total: 0,
        },
        search: "",
        selection: {
            id: "id",
            selected: [],
        },
        selectionActions: null,
        with: [],
        periodType: "predefined",
    },
    action: AnyAction
) {
    switch (action.type) {
        case FETCH_TICKETS_SUCCESS:
            if (action.response.config.params._table_id === state.id) {
                return tableResponse(state, action);
            }
            return state;

        case MANAGE_TABLE:
            if (action.data.id === state.id) {
                return {
                    ...state,
                    ...action.data,
                };
            }
            return state;

        case TABLE_TICKETS_UPDATE:
            const newAction = {
                ...action,
                data:
                    typeof action.data === "object" && action.data !== null
                        ? omit(action.data, "periodType")
                        : action.data,
            };
            return action.action === "clearFilters"
                ? {
                      ...tableUpdate(state, newAction),
                      periodType: "predefined",
                  }
                : {
                      ...tableUpdate(state, newAction),
                      periodType: action.data?.periodType || state.periodType,
                  };

        default:
            return state;
    }
}

export default EventsTicketsReducer;
